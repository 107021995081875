import { Container, NormalText } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import { MAX_MEDIUM_SCREEN_WIDTH, MAX_SMALL_SCREEN_WIDTH } from '../GlobalStyles'

const CustomContainer = styled(Container)`
  margin-bottom: 120px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 100px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 72px;
  }
`
const TitleText = styled(NormalText)`
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    justify-content: center;
    text-align: center;
  }
`
const BodyText = styled(NormalText)`
  max-width: 920px;
  margin-top: 34px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    max-width: 746px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    text-align: center;
    max-width: 100%;
    margin-top: 20px;
  }
`

const ClassificationTitle: React.SFC = () => (
  <CustomContainer flexDirection="column">
    <TitleText className="hero-title">GIC Classification</TitleText>
    <BodyText secondary>
      All components in the ONN database are classified according to the GIC classification. The
      classification is applied to implants for joints, spine, trauma, sports medicine, and a few
      other products. Many of the specific GIC codes have been subclassified in order to accommodate
      the changing technology of orthopedics. The complete classification and subclassification is
      available to subscribers.
    </BodyText>
  </CustomContainer>
)

export default ClassificationTitle
