import { GicsWithImagesData } from '@curvo/apollo'
import { Container, Flex, NormalText, Spinner } from '@curvo/common-ui'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import styled from 'styled-components'
import Client from '../../config/Apollo'
import { MAX_SMALL_IPAD_SCREEN_WIDTH, MAX_SMALL_SCREEN_WIDTH } from '../GlobalStyles'
import GICClassifiationNode from './GICClassifiationNode'
import GICClassificationSample from './GICClassificationSample'

///////////////////////////
////
///////////////////////////
const bodyText = `Each manufactured component is assigned a GIC code. Icons are provided to create a visual link to what the device is. There is a dark border around the icon which identifies it as a “generic" picture. This may not be what the actual device looks like. \n
The GIC codes may be subclassified for specific technologies. For example, GIC 21 (Hip femur) is subclassified into “Coated”, “Uncoated”, “Revision”, “1 piece”, and “Endo” categories designated as Type1. \n
The Type1 categories may be further subdivided as necessary. For example, the GIC category of 21 (Hip femur), Type1 Revision, is subdivided Into “Stem”, "Body”, "1 Piece”, and “Temp”. \n
When possible and necessary, the major material of the devices are determined and entered as a two-digit code. For example, a hip stem made out of cobalt chromium alloy would have a material code of “CC”. For some devices, the material is not relevant. \n
Component sizes are also recorded when possible and necessary. Three Separate sizes are maintained for each part and the sizes mean the same thing for each GIC. For example, the outside diameter of a femoral head is designated as Size1 for GIC 22. For trauma plates, SizeT1 corresponds to length of the plate, Size2 Is hole diameter of the plate, and Size3 is the number of holes in the plate. For some devices, such as implanted stimulators, sizes are not relevant.`

///////////////////////////
////
///////////////////////////
const ContentContainer = styled(Flex)`
  width: 100%;
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    flex-direction: column;
  }
`
const SectionText = styled(NormalText)`
  border-bottom: 1px solid;
  align-items: flex-end;
  min-height: 3em;
`
const GICClassNodesContainer = styled(Flex)<{ numOfItems: number }>`
  grid-auto-flow: column;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    flex-direction: column;
  }
`
const GICClassNodesColumn = styled(Flex)`
  flex: 1;
  flex-direction: column;
`
const DescriptionText = styled(NormalText)`
  white-space: pre-wrap;
`

///////////////////////////
////
///////////////////////////
const GICClassificationContent: React.SFC = () => (
  <Container>
    <ApolloProvider client={Client.getClient()}>
      <GicsWithImagesData variables={{ first: 50 }}>
        {({ data, loading, error }) => {
          if (error || !data) {
            console.error('GIC WITH IMAGES', error)
            return <NormalText danger>Error</NormalText>
          }
          if (loading) {
            return <Spinner center />
          }
          return (
            <ContentContainer>
              <div style={{ flex: 2 }}>
                <SectionText className="heading">Icon/GIC/Description</SectionText>
                {data.getAllGicWithImages && !!data.getAllGicWithImages.edges.length ? (
                  <GICClassNodesContainer numOfItems={data.getAllGicWithImages.edges.length}>
                    {data.getAllGicWithImages.edges
                      .reduce<any[][]>((preVal, currVal, curIndex) => {
                        const maxRow = Math.ceil(data.getAllGicWithImages.edges.length / 2)

                        if (curIndex % maxRow === 0) {
                          preVal.push([currVal])
                        } else {
                          preVal[Math.floor(curIndex / maxRow)].push(currVal)
                        }
                        return preVal
                      }, [])
                      .map((columnGroup, groupIndex) => (
                        <GICClassNodesColumn key={'gic-column-' + groupIndex}>
                          {columnGroup.map((edge, index) => {
                            const { id, name, imageUrl } = edge.node
                            return (
                              <GICClassifiationNode
                                key={index + id + name}
                                id={id}
                                name={name}
                                imageUrl={imageUrl}
                                margin="6px 0 6px 0"
                                paddingRight="12px"
                              />
                            )
                          })}
                        </GICClassNodesColumn>
                      ))}
                  </GICClassNodesContainer>
                ) : (
                  <NormalText className="heading">No GIC get</NormalText>
                )}
              </div>
              <div style={{ flex: 1 }}>
                <SectionText className="heading">Elements of GIC Classification</SectionText>
                <Flex flexDirection="column" alignItems="center">
                  <DescriptionText alignSelf="stretch" marginBottom="24px">
                    {bodyText}
                  </DescriptionText>
                  <GICClassificationSample />
                </Flex>
              </div>
            </ContentContainer>
          )
        }}
      </GicsWithImagesData>
    </ApolloProvider>
  </Container>
)
export default GICClassificationContent
