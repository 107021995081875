import React from 'react'
import styled from 'styled-components'
import BackgroundBigRect from '../components/BackgroundBigReact'
import BackgroundSmallRect from '../components/BackgroundSmallRect'
import { GICClassificationContent, GICClassificationTitle } from '../components/GICClassification'
import { MAX_MEDIUM_SCREEN_WIDTH, MAX_SMALL_SCREEN_WIDTH } from '../components/GlobalStyles'
import Layout from '../components/layout'

const CustomContainer = styled.div`
  position: relative;
  overflow: visible;

  margin-bottom: 204px;
  margin-top: 56px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 134px;
    margin-top: 58px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 66px;
    margin-top: 120px;
  }
`

class GICClassificationPage extends React.Component {
  render() {
    return (
      <Layout>
        <CustomContainer>
          <BackgroundBigRect />
          <BackgroundSmallRect />
          <GICClassificationTitle />
          <GICClassificationContent />
        </CustomContainer>
      </Layout>
    )
  }
}

export default GICClassificationPage
