import { Colors, Flex, NormalText } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import sampleImg from '../../images/GICClassification/gic_example.png'

///////////////////////////
////
///////////////////////////
const sampleData = [
  {
    key: 'GIC',
    detail: '21 Femoral stem',
  },
  {
    key: 'Type1',
    detail: 'Revision',
  },
  {
    key: 'Type2',
    detail: 'Stem',
  },
  {
    key: 'Material',
    detail: 'TI (titanium)',
  },
  {
    key: 'Size1',
    detail: '155 (Length)',
  },
]

///////////////////////////
////
///////////////////////////
const CustomFlex = styled(Flex)`
  background-color: ${Colors.Secondary};
  flex-direction: column;
  font-size: 12px;
  padding: 12px;
  max-width: 400px;
`
const DataContainer = styled.div`
  flex: 2;
  font-size: 12px;
`

const Img = styled.img`
  width: 100%;
`

///////////////////////////
////
///////////////////////////
const GICClassificationSample: React.SFC = () => (
  <CustomFlex>
    <NormalText className="small-title">
      Sample GIC TM, Type1, Type2, Material, Size1 Assignment
    </NormalText>
    <NormalText marginTop="12px" marginBottom="12px">
      Stryker part 6276-7-016 Modular Conical distal stem 16x155:
    </NormalText>
    <Flex>
      <DataContainer>
        {sampleData.map((row, index) => (
          <Flex key={`${row}_${index}_${row.key}_${row.detail}`}>
            <NormalText flex="2">{row.key}:</NormalText>
            <NormalText flex="3">{row.detail}</NormalText>
          </Flex>
        ))}
      </DataContainer>
      <div style={{ flex: 1 }}>
        <Img src={sampleImg} />
      </div>
    </Flex>
  </CustomFlex>
)

export default GICClassificationSample
