import {
  Colors,
  extractMarginAndPadding,
  Flex,
  MarginProps,
  NormalText,
  PaddingProps,
} from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'

///////////////////////////
////
///////////////////////////
const CustomFlex = styled(Flex)<{ width?: string }>`
  ${props => props.width && `width: ${props.width}`};
`

const ImgContainer = styled(Flex)<{ size?: string }>`
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.Secondary};
  width: ${props => (props.size ? props.size : '3em')};
  height: ${props => (props.size ? props.size : '3em')};

  img {
    width: ${props => (props.size ? props.size : '3em')};
    height: ${props => (props.size ? props.size : '3em')};
  }
`
///////////////////////////
////
///////////////////////////
type Props = {
  id: number
  name: string
  imageUrl?: string | null
  height?: string
  width?: string
} & MarginProps &
  PaddingProps
const GICClassifiationNode: React.SFC<Props> = props => {
  const { id, name, imageUrl, height, width } = props
  const { paddings, margins } = extractMarginAndPadding(props)
  return (
    <CustomFlex {...margins} {...paddings} width={width}>
      <ImgContainer size={height}>
        {imageUrl ? <img src={imageUrl} /> : <NormalText>No</NormalText>}
      </ImgContainer>
      <NormalText marginLeft="16px" marginRight="16px">
        {id}
      </NormalText>
      <NormalText>{name}</NormalText>
    </CustomFlex>
  )
}
export default GICClassifiationNode
